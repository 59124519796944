<template>
  <div class="touchpoints-import">
    <UiBanner large :title="$t('touchpoints.importTool.title')" :subtitle="$t('touchpoints.importTool.subtitle')">
    </UiBanner>
    <UiContainer large>
      <section>
        <UiTitle :title="$t('touchpoints.importTool.export.title')" />
        <UiSubtitle>
          <span>{{ $t('touchpoints.importTool.export.informations.start') }}</span>
          <router-link :to="{ name: 'TouchpointsList' }">{{
            $t('touchpoints.importTool.export.informations.link')
          }}</router-link>
          <span>{{ $t('touchpoints.importTool.export.informations.end') }}</span>
        </UiSubtitle>

        <v-alert :icon="icons.mdiAlertCircleOutline" class="tw-mt-6" text dense>
          <span v-dompurify-html="$t('touchpoints.importTool.export.disclaimer.text')"></span>
        </v-alert>
        <div class="tw-text-center tw-mt-6">
          <v-btn color="primary" rounded :disabled="exporting" :loading="exporting" @click="exportTouchpoints"
            >{{ $t('touchpoints.importTool.export.action') }}
          </v-btn>
        </div>
      </section>
      <section class="tw-mt-12">
        <UiTitle :title="$t('touchpoints.importTool.import.title')" />
        <UiSubtitle :subtitle="$t('touchpoints.importTool.import.informations.start')" />
        <div class="touchpoints-import__data-conditions">
          <UiTitle class="tw-text-md" :title="$t('touchpoints.importTool.import.disclaimer.title')" small></UiTitle>
          <ul class="touchpoints-import__data-conditions__list">
            <li class="touchpoints-import__data-conditions__list__item">
              <v-icon class="tw-mr-2">{{ icons.mdiArrowRightThinCircleOutline }}</v-icon
              >{{ $t('touchpoints.importTool.import.disclaimer.rules.integer') }}
            </li>
            <li class="touchpoints-import__data-conditions__list__item">
              <v-icon class="tw-mr-2">{{ icons.mdiArrowRightThinCircleOutline }}</v-icon
              >{{ $t('touchpoints.importTool.import.disclaimer.rules.empty') }}
            </li>
          </ul>
          <UiInputFile
            class="tw-mb-6"
            :file="importFile"
            :file-formats="fileFormat.sheet"
            :uploading="uploading"
            @change="checkFile"
          />
        </div>
        <div class="touchpoints-import__file-conditions">
          <UiTitle :title="$t('touchpoints.importTool.import.fileValidation.title')" small />
          <ul class="touchpoints-import__file-conditions__list">
            <li
              v-for="(value, key, idx) in fileValidation"
              :key="`fileValidation${idx}`"
              class="touchpoints-import__file-conditions__list__item"
              :class="{
                'error--text': fileError(key),
                'success--text': fileSucess(key),
              }"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon v-if="!importFile">{{ icons.mdiArrowRightThinCircleOutline }}</v-icon>
                <v-icon v-else-if="fileError(key)" color="error">{{ icons.mdiCloseCircleOutline }}</v-icon>
                <v-icon v-else-if="fileSucess(key)" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
                <span>{{ $t(`touchpoints.importTool.import.fileValidation.${key}`) }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="tw-text-center tw-mt-6">
          <v-btn :disabled="uploadDisabled" :loading="uploading" color="primary" rounded @click="importTouchpoints"
            >{{ $t('touchpoints.importTool.import.action') }}
          </v-btn>
        </div>
      </section>
    </UiContainer>
  </div>
</template>

<script>
import {
  mdiArrowRightThinCircleOutline,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
} from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiInputFile from '@/components/UI/input/File.vue'
import { fileFormat } from '@/config/fileFormatTouchpoints.config'
import { mapActions } from 'vuex'

const FILE_MAX_SIZE = 10048576
let initialData = () => ({
  importFile: null,
  errorCode: '',
  errorData: '',
  fileUploaded: false,
  fileValidation: {
    format: false,
    size: false,
  },
})

export default {
  name: 'TouchpointsImport',
  components: {
    UiBanner,
    UiTitle,
    UiSubtitle,
    UiContainer,
    UiInputFile,
  },
  data: () => ({
    icons: { mdiArrowRightThinCircleOutline, mdiCloseCircleOutline, mdiCheckCircleOutline, mdiAlertCircleOutline },
    uploading: false,
    exporting: false,
    fileFormat,
    ...initialData(),
  }),
  computed: {
    uploadDisabled() {
      return (
        this.importFile === null ||
        this.uploading ||
        (this.importFile !== null && (!this.fileValidation.format || !this.fileValidation.size))
      )
    },
  },
  methods: {
    ...mapActions({
      getCSVDownloadUrl: 'touchpoints/getCSVDownloadUrl',
      setAlert: 'backoffice/setAlert',
      sendFile: 'touchpoints/sendFile',
    }),

    fileSucess(key) {
      return this.importFile && this.fileValidation[key]
    },
    fileError(key) {
      return this.importFile && !this.fileValidation[key]
    },
    dataSuccess(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === 1
    },
    dataError(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === -1
    },
    checkFile(file) {
      this.resetData()
      const extension = file.name.split('.').pop()
      this.fileValidation.format = fileFormat.sheet.includes(extension)
      this.fileValidation.size = file.size < FILE_MAX_SIZE
      this.importFile = file
    },
    async exportTouchpoints() {
      this.exporting = true
      try {
        await this.getCSVDownloadUrl({
          _ids: [],
          search: {},
          filters: {},
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.exporting = false
    },
    async importTouchpoints() {
      this.uploading = true

      let formData = new FormData()
      formData.append('file', this.importFile)

      try {
        await this.sendFile(formData)
        this.fileUploaded = true
        this.uploading = false
        this.success = true
        this.setAlert({
          color: 'success',
          text: this.$t('touchpoints.management.notification.success'),
        })
        this.resetData()
      } catch ({ data }) {
        this.errorCode = data.error ? data.error.type : null
        this.errorData = data.error ? data.error.message : ''
        switch (this.errorCode) {
          case 'quantity':
            this.fileValidation.quantity = false
            break
          case 'id':
            this.fileValidation.id = false
            break
          default:
            this.setAlert({
              color: 'error',
              text: this.$t('error.notification.default'),
            })
        }
        this.fileUploaded = true
        this.uploading = false
      }
    },

    resetData() {
      Object.assign(this.$data, initialData())
    },
  },
}
</script>

<style lang="scss" scoped>
.touchpoints-import {
  &__file-conditions {
    @apply tw-mt-12;
  }

  &__file-conditions,
  &__data-conditions {
    @apply tw-mt-6;

    &__list {
      @apply tw-mt-2 tw-list-none tw-m-0 tw-p-0;

      &__item {
        @apply tw-mt-1;

        &:first-child {
          @apply tw-mt-0;
        }
      }
    }
  }
}
</style>
